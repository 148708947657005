import { createGlobalStyle } from "styled-components"

const TypographyStyles = createGlobalStyle`
  .heading-1 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 400;
    font-size: 4.5rem;
    color: var(--color-grey-dark-1);
    line-height: 1;

    @media only screen and (max-width: var(--bp-large)) {
      font-size: 3.5rem;
    }

    @media only screen and (max-width: var(--bp-medium)) {
      font-size: 3.5rem;
    }

    @media only screen and (max-width: var(--bp-small)) {
      font-size: 2.5rem;
    }
  }
`
export default TypographyStyles
