import React, { useContext } from "react"
import GlobalStyles from "../styles/GlobalStyles"
import TypographyStyles from "../styles/TypographyStyles"
import LayoutStyles from "../styles/LayoutStyles"
import ThemeContext from "./ThemeContext"

export default function Layout({ children }) {
  const [theme] = useContext(ThemeContext)
  return (
    <div>
      <GlobalStyles />
      <TypographyStyles />
      <LayoutStyles breakpoints={theme.breakpoints}>{children}</LayoutStyles>
    </div>
  )
}
