import React, { useState } from "react"

const ThemeContext = React.createContext()

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState({
    breakpoints: {
      largest: "75em",
      large: "62.5em",
      medium: "50em",
      small: "37.5em",
    },
  })
  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeContext
