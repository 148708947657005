import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    --color-primary-light: #e55039;
    --color-primary: #EB2F06;
    --color-primary-dark: #CC2804;

    --color-grey-light-1: #f9f7f6;
    --color-grey-light-2: #aaa;

    --color-grey-dark-1: #747d8c;
    --color-grey-dark-2: #2f3542;

    --color-white: #fff;
    --color-black: #000;

    // FONT VARIABLES
    --font-primary: "Nunito", sans-serif;
    --font-display: "Josefin Sans", sans-serif;
    --font-secondary: "Roboto", sans-serif;

    // RESPONSIVE BREAKPOINTS
    --bp-largest: 75em; // 1200px
    --bp-large: 62.5em; // 1000opx
    --bp-medium: 50em; // 800px
    --bp-small: 37.5em; // 600p
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
    scroll-behavior: smooth;

    @media only screen and (max-width: var(--bp-medium)) {
      font-size: 55.5%; // 10px/16px = 62.5% -> 1rem = 10px
    }

    @media only screen and (max-width: var(--bp-small)) {
      font-size: 50.5%; // 10px/16px = 62.5% -> 1rem = 10px
    }
  }

  body {
    font-family: var(--font-primary);
    color: var(--color-grey-dark-2);
    font-weight: 300;
    line-height: 1.6;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  }
`
export default GlobalStyles
