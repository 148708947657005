import styled from "styled-components"

const LayoutStyles = styled.div`
  #root {
    overflow-x: hidden;
    display: grid;
    justify-content: center;
    justify-items: center;
  }

  .container {
    display: grid;
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: [content-start] 1fr [content-end];

    @media only screen and (min-width: ${props => props.breakpoints.largest}) {
      max-width: 1200px;
    }

    @media only screen and (max-width: ${props => props.breakpoints.largest}) {
      max-width: 90vw;
    }

    @media only screen and (max-width: ${props => props.breakpoints.medium}) {
      max-width: 100vw;
    }

    @media only screen and (max-width: ${props => props.breakpoints.small}) {
      width: 100vw;
    }
  }
`
export default LayoutStyles
