/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import Layout from "./src/components/Layout"
import { ThemeProvider } from "./src/components/ThemeContext"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <ThemeProvider>{element}</ThemeProvider>
}
